import { tns } from "tiny-slider/src/tiny-slider"
import AOS from "aos/dist/aos"
AOS.init({startEvent: 'load',once: true});

tns({
    container: '.slider-1',
    axis: "vertical",
    items: 1,
    autoplay: true,
    autoplayTimeout: 1300,
    nav: false,
    autoplayButtonOutput: false,
    controls: false,
    loop: true,
});
tns({
    container: '.slider-2',
    axis: "vertical",
    items: 1,
    autoplay: true,
    autoplayTimeout: 1300,
    nav: false,
    autoplayButtonOutput: false,
    controls: false,
    loop: true,
});
tns({
    container: '.slider-3',
    axis: "vertical",
    items: 1,
    autoplay: true,
    autoplayTimeout: 1300,
    nav: false,
    autoplayButtonOutput: false,
    controls: false,
    loop: true,
});
tns({
    container: '.slider-4',
    axis: "vertical",
    items: 1,
    autoplay: true,
    autoplayTimeout: 1300,
    nav: false,
    autoplayButtonOutput: false,
    controls: false,
    loop: true,
});
tns({
    container: '.slider-5',
    axis: "vertical",
    items: 1,
    autoplay: true,
    autoplayTimeout: 1300,
    nav: false,
    autoplayButtonOutput: false,
    controls: false,
    loop: true,
});
tns({
    container: '.slider-6',
    axis: "vertical",
    items: 1,
    autoplay: true,
    autoplayTimeout: 1300,
    nav: false,
    autoplayButtonOutput: false,
    controls: false,
    loop: true,
});
tns({
    container: '.slider-7',
    axis: "vertical",
    items: 1,
    autoplay: true,
    autoplayTimeout: 1300,
    nav: false,
    autoplayButtonOutput: false,
    controls: false,
    loop: true,
});
tns({
    container: '.slider-8',
    axis: "vertical",
    items: 1,
    autoplay: true,
    autoplayTimeout: 1300,
    nav: false,
    autoplayButtonOutput: false,
    controls: false,
    loop: true,
});

window.onscroll = function() {myFunction()};

// Get the header
var header = document.getElementById("nav");
// Get the offset position of the navbar
var sticky = header.offsetTop;

// Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
function myFunction() {
    if(window.pageYOffset > 0) {
        header.classList.add("bg-blue-dark-medium");
    }else {
        header.classList.remove("bg-blue-dark-medium");
    }
}



const btn = document.querySelector("#mobile-menu");
const sidebar = document.querySelector("#sidebar");

// add our event listener for the click
btn.addEventListener("click", () => {
    sidebar.classList.toggle("-translate-x-full");
});

document.addEventListener('click', (event) => {
    const withinBoundariesSidebar = event.composedPath().includes(sidebar)
    const withinBoundariesBtn = event.composedPath().includes(btn)
    if (!withinBoundariesSidebar && !withinBoundariesBtn) {
        sidebar.classList.add("-translate-x-full");
    }
})
var timer = null;
window.addEventListener('scroll', function() {
    document.getElementById('nav').classList.add('ease-in-out')
    document.getElementById('nav').classList.add('duration-200')
    document.getElementById('nav').classList.add('-translate-y-full')
    if(timer !== null) {
        clearTimeout(timer);
    }
    timer = setTimeout(function() {
        document.getElementById('nav').classList.remove('-translate-y-full')
    }, 500);
}, false);
particlesJS.load('particles-js', 'media/particles.json');
